.Layout {
  min-height: 100%;
}

.Drawer {
  padding: 24px;
  border-bottom: 24px solid #ffffff;

  background: linear-gradient(to left, rgba(0, 8, 0, 0.6), transparent),
    url("../../Resources/images/detailsBG.png") 100% 100% no-repeat;
  // background-repeat: no-repeat;
  // background-position: 100% 100%;
  // background-size: inherit;

  -webkit-transition: background-color 0.3s linear, border-color 0.3s linear;
  -ms-transition: background-color 0.3s linear, border-color 0.3s linear;
  transition: background-color 0.3s linear, border-color 0.3s linear;

  &__Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 24px;

    &__Profile {
      display: flex;
      align-items: center;

      &--Name {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 130%;
        /* identical to box height, or 21px */

        color: #ffffff;

        display: flex;
        align-items: center;

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(1.3 * 1.6rem);
          width: 50px;
        }
      }

      &--Img {
        display: block;
        margin-right: 16px;

        border: 2px solid #ffffff;

        background-color: #eeeaff;
        box-sizing: border-box;

        width: 48px;
        height: 48px;

        border-radius: 50%;
        overflow: hidden;
      }
    }
    &__Points {
      background: rgba(255, 255, 255, 1);
      border-radius: 120px;

      display: flex;

      padding: 10px 12px;

      border-radius: 120px;

      &--Count {
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 130%;
        /* identical to box height, or 16px */

        text-align: right;

        color: rgba(0, 0, 0, 0.87);

        margin-right: 4px;

        display: flex;
        align-items: center;

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(1.3 * 1.2rem);
          width: 30px;
        }
      }
    }
  }
  &__Body {
    margin-bottom: 24px;

    display: grid;
    // grid-template-columns: 1fr 1fr;

    &__Stats {
      &--Earning {
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 100%;
        /* identical to box height, or 20px */

        display: flex;
        align-items: center;

        color: #ffffff;

        margin-bottom: 10px;

        display: flex;
        align-items: center;

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(2rem);
          width: 50px;
        }
      }
      &--Goal {
        font-style: normal;
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 130%;
        /* or 18px */

        color: #ffffff;

        margin-top: 8px;

        display: flex;
        align-items: center;

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(1.3 * 1.4rem);
          width: 50px;

          margin-left: 4px;
        }
      }
    }
  }
  &__Button {
    width: fit-content;
    display: flex;
    align-items: center;

    background-color: white;
    border-radius: 4px;

    padding: 12px;

    &--Text {
      font-style: normal;
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 130%;
      /* or 18px */

      color: rgba(0, 0, 0, 0.87);
    }

    img {
      margin-right: 8px;
    }
  }
}

.NavBar {
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
  scroll-behavior: smooth;

  margin-top: -26px;

  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  // justify-content: center;

  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
  // border-radius: 24px 24px 0px 0px;

  // padding: 0 16px;

  z-index: 10;

  &::-webkit-scrollbar {
    display: none;
  }

  &__MenuItem {
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    mix-blend-mode: normal;
    padding: 16px 0px;
    white-space: nowrap;
    min-width: 30vw;
    margin: 0 10px;
  }
}

.Nav {
  background-color: #333333;
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
}
