.Leaderboard {
  // background: url(../../Resources/images/Medals.svg);
  // background-position: 100% 0%;
  // background-repeat: no-repeat;

  &__Body {
    // margin-top: 48px;
    padding: 24px;

    &--Prize {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
    }
  }

  &__Heading {
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 150%;
    /* identical to box height, or 33px */

    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.87);
  }
  &__Subheading {
    margin-top: 8px;
    font-weight: 450;
    font-size: 1.6rem;
    line-height: 130%;

    color: rgba(0, 0, 0, 0.6);
  }

  &__Storycard {
    // border: 1px solid grey;
    background-color: #c45276;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    position: relative;

    &__Left {
      z-index: 5;
      &--text {
        color: white;
        font-weight: 900;
        font-size: 16px;
        line-height: 130%;
      }

      &--button {
        margin-top: 16px;
        background-color: #fff;
        padding: 8px 13px;
        width: fit-content;
        color: #c45276;
        border-radius: 4px;
        font-size: 12px;
        line-height: 130%;
      }
    }

    &__Right {
      z-index: 5;
    }

    &__Blob {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    &__Lines {
      position: absolute;
      left: 3%;
      bottom: 0;
      z-index: 2;
    }
  }
}
