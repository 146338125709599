.ProgressBar {
  height: 8px;
  width: 100%;

  background-color: white;

  border: 0.2px solid #e5e5e5;
  border-radius: 120px;

  display: flex;
  align-items: center;

  overflow: visible;
  position: relative;

  span {
    display: block;
    height: 100%;
  }

  &__Indicator {
    border-radius: 4px;
    overflow: hidden;

    &--Fill {
      -webkit-animation-name: progressBar;
      animation-name: progressBar;

      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      animation-delay: 1.5s;
      -webkit-animation-delay: 1.5s;
    }
  }
}

.FirstMile {
  position: absolute;
  left: 10%;
  top: -38px;
}

.SecondMile {
  position: absolute;
  left: 34%;
  top: -38px;
}

.ThirdMile {
  position: absolute;
  left: 61%;
  top: -38px;
}

.FourthMile {
  position: absolute;
  left: 85%;
  top: -38px;
}

.line1 {
  position: absolute;
  left: 16%;
  bottom: -2px;
}

.line2 {
  position: absolute;
  left: 40%;
  bottom: -2px;
}

.line3 {
  position: absolute;
  left: 66%;
  bottom: -2px;
}

.line4 {
  position: absolute;
  left: 91%;
  bottom: -2px;
}
@-webkit-keyframes progressBar {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}

@keyframes progressBar {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}
