.StatsSection {
  padding: 20px;
  // margin-bottom: 24px;
}

.StatsCard {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 24px;

  &__Left {
    display: flex;
    align-items: flex-start;

    &__Logo {
      margin-right: 12px;
    }
    &__Body {
      &--Label {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 130%;
        /* identical to box height, or 24px */

        display: flex;
        align-items: center;

        color: rgba(255, 255, 255, 1);

        margin-bottom: 2px;
      }
      &--Stat {
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 150%;
        /* identical to box height, or 18px */

        display: flex;
        align-items: center;

        color: rgba(255, 255, 255, 1);

        margin-bottom: 12px;
      }
      &--Button {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 21px */

        color: #453983;
        background-color: #ffffff;
        border: 1px solid #453983;
        box-sizing: border-box;
        border-radius: 4px;

        padding: 8px 12px;

        width: fit-content;
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__Points {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 24px;
      height: 24px;

      margin-bottom: 4px;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 130%;
      /* identical to box height, or 16px */

      text-align: right;

      color: rgba(255, 255, 255, 0.8);
    }
  }
}
