.PeaknStatsCard {
  width: 100%;
  height: 120px;
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &_left {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    /* or 36px */

    color: rgba(255, 255, 255, 1);
    // border: 1px solid white;

    display: flex;
    align-items: center;

    padding: 29px 0 29px 20px;
  }
  &_right {
    display: flex;
    justify-self: flex-end;

    img {
      width: 100%;
    }
  }
}
